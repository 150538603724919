/* eslint-disable react-hooks/exhaustive-deps */
import { STATIC_URL } from "@src/components/partials/landing-pages/constants";
import { useLocation } from "@reach/router";
import PTCHero, { PTCHeroProps } from "@src/components/partials/parent-teacher-conference/PTCHero";
import PTCResourceKit, { PTCResourceKitProps } from "@src/components/partials/parent-teacher-conference/PTCResourceKit";
import PTCSignup, { PTCSignupProps } from "@src/components/partials/parent-teacher-conference/PTCSignups";
import PlayIcon from "@src/assets/images/ptc/play-icon.svg";
import { getVimeoThumb } from "@src/utils/vimeoThumb";
import { ModalContext, ModalType } from "@src/components/modals/ModalController";
import PTCPrintables, { PTCPrintablesProps } from "@src/components/partials/parent-teacher-conference/PTCPrintables";
import PTCFeatures, { PTCFeaturesProps } from "@src/components/partials/parent-teacher-conference/PTCFeatures";
import { graphql, useStaticQuery } from "gatsby";
import React, { useContext, useEffect } from "react";
import { logEvent } from "@src/utils/logClient";
import SEO from "@src/components/SEO";

const ParentTeacherConferencePage = () => {
  const {
    directus: { page_parent_teacher_conference },
  } = useStaticQuery(graphql`
    {
      directus {
        page_parent_teacher_conference {
          hero_image {
            id
            filename_disk
          }
          resource_kit_cta_url
          resource_kit_video_url
          signups_icon {
            id
            filename_disk
          }
          signups_tutorial_video_url
          signups_cta_url
          signups_feature_image {
            id
            filename_disk
          }
          printables_section {
            image {
              id
              filename_disk
            }
            cta_url
          }
          features_image {
            id
            filename_disk
          }
          features_list {
            thumb {
              id
              filename_disk
            }
            cta_url
            is_video
          }
        }
      }
    }
  `);

  const {
    hero_image,
    resource_kit_cta_url,
    resource_kit_video_url,
    signups_tutorial_video_url,
    signups_icon,
    signups_cta_url,
    signups_feature_image,
    printables_section,
    features_image,
    features_list,
  } = page_parent_teacher_conference;

  const modalContext = useContext(ModalContext);

  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const showModal = () => modalContext.showModal(ModalType.VimeoModal, { videoUrl: signups_tutorial_video_url });

  useEffect(() => {
    logEvent({
      eventName: "web.external_page.parent_teacher_conference.page_view",
      eventValue: location.href,
      metadata: params,
    });
  }, []);

  const ptcHeroProps: PTCHeroProps = {
    heading: "directus.page_parent_teacher_conference.hero_heading",
    tagline: "directus.page_parent_teacher_conference.hero_tagline",
    image: STATIC_URL + hero_image.filename_disk,
  };

  const ptcResourceKitProps: PTCResourceKitProps = {
    heading: "directus.page_parent_teacher_conference.resource_kit_heading",
    tagline: "directus.page_parent_teacher_conference.resource_kit_tagline",
    cta: {
      label: "directus.page_parent_teacher_conference.resource_kit_cta_label",
      url: resource_kit_cta_url,
    },
    videoUrl: resource_kit_video_url,
  };

  const ptcSignupsProps: PTCSignupProps = {
    icon: STATIC_URL + signups_icon.filename_disk,
    heading: "directus.page_parent_teacher_conference.singups_heading",
    description: "directus.page_parent_teacher_conference.signups_tagline",
    cta: {
      label: "directus.page_parent_teacher_conference.signups_cta_label",
      url: signups_cta_url,
    },
    video: {
      firstLine: "directus.page_parent_teacher_conference.signups_tutorial_line_1",
      secondLine: "directus.page_parent_teacher_conference.signups_tutorial_line_2",
      thirdLine: "directus.page_parent_teacher_conference.signups_tutorial_line_3",
      icon: PlayIcon,
      openModal: showModal,
      image: getVimeoThumb(signups_tutorial_video_url),
    },
    image: STATIC_URL + signups_feature_image.filename_disk,
  };

  const ptcPrintablesProps: PTCPrintablesProps = {
    printables: printables_section.map(({ cta_url, image }, index: number) => ({
      heading: `directus.page_parent_teacher_conference_printables_${index + 1}.title`,
      description: `directus.page_parent_teacher_conference_printables_${index + 1}.description`,
      image: STATIC_URL + image.filename_disk,
      caption: `directus.page_parent_teacher_conference_printables_${index + 1}.caption`,
      cta: {
        label: `directus.page_parent_teacher_conference_printables_${index + 1}.cta_label`,
        url: cta_url,
      },
    })),
  };

  const ptcFeaturesProps: PTCFeaturesProps = {
    icon: STATIC_URL + features_image.filename_disk,
    heading: "directus.page_parent_teacher_conference.features_heading",
    features: features_list.map(({ thumb, cta_url, is_video }, index: number) => ({
      image: STATIC_URL + thumb.filename_disk,
      text: `directus.page_parent_teacher_conference_features_${index + 1}.title`,
      cta: {
        url: cta_url,
        label: `directus.page_parent_teacher_conference_features_${index + 1}.cta_label`,
      },
      isVideo: is_video,
    })),
  };

  return (
    <>
      <SEO
        title="Your partner for parent-teacher conferences"
        description="It’s time to share students’ progress with families and we’ve got your back."
        noindex
      />
      <PTCHero {...ptcHeroProps} />
      {/* <PTCResourceKit {...ptcResourceKitProps} /> */}
      <PTCSignup {...ptcSignupsProps} />
      <PTCPrintables {...ptcPrintablesProps} />
      <PTCFeatures {...ptcFeaturesProps} />
    </>
  );
};

export default ParentTeacherConferencePage;
